<!--
 * @Description: 短信下发
 * @Author: ChenXueLin
 * @Date: 2022-01-04 16:42:37
 * @LastEditTime: 2022-05-13 10:43:37
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-form
      label-width="130px"
      ref="addForm"
      :rules="addFormRules"
      :model="addForm"
    >
      <el-form-item label="发出的号码">
        {{ 204 }}
      </el-form-item>
      <el-form-item label="接受短信号码" prop="receiveNumber">
        <el-input
          placeholder="接受短信号码"
          v-model="addForm.receiveNumber"
          type="textarea"
          :rows="5"
          @input="
            addForm.receiveNumber = isNaN(addForm.receiveNumber)
              ? addForm.receiveNumber.replace(/[^\d\,]/g, '')
              : addForm.receiveNumber
          "
        ></el-input>
      </el-form-item>
      <el-form-item label="发送的短信内容" prop="smsContent">
        <el-input
          placeholder="发送的短信内容"
          v-model="addForm.smsContent"
          type="textarea"
          :rows="5"
          maxlength="255"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import base from "@/mixins/base";

export default {
  name: "",
  components: {},
  data() {
    return {
      addForm: {
        receiveNumber: "", //接收短信号码
        smsContent: "", //发送短信内容
        remark: "" //备注
      },
      addFormRules: {
        receiveNumber: [
          {
            required: true,
            message: "请输入接收短信号码",
            trigger: ["blur", "change"]
          }
        ],
        smsContent: [
          {
            required: true,
            message: "请输入发送短信内容",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    //重置表单
    resetForm() {
      this.addForm = {
        receiveNumber: "", //接收短信号码
        smsContent: "", //发送短信内容
        remark: "" //备注
      };
      this.$refs.addForm.resetFields();
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.el-form {
  margin-top: 20px;
  .el-input {
    width: 340px;
  }
  .el-textarea {
    width: 340px;
  }
}
</style>
